import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import SEO from "../components/seo"
import logo from "../../content/assets/logo-black.svg"
import {Link} from "gatsby"

const page404 = (props) => {

  return (
    <div className="studio-page404">

      <div class="studio-page404-in">
        <div className="studio-price-maintitle tac">
                      <img src={logo} className="mb40" alt=""/>
          <p className="studio-price-subtitle ffpd tac color-black mb40">Valitettavasti sivua ei löytetty</p>
            <div>
              <Link to="/" className="studio-btn-secondary ffpd">Etusivu</Link>
            </div>
        </div>
      </div>
    </div>
  )

}

export default page404
